
import {defineComponent, PropType} from "vue";
import { attachmentResponse, FileExt, ImagesPreview, TaskComment } from "@/shared/api";
import {saveAs} from "file-saver";
import Preloader from "@/shared/ui/preloader";
//@ts-ignore
import VueLoadImage from 'vue-load-image'
import MessageFile from "@/components/chat/message_type/file/MessageFile.vue";
import VueLoadFile from "@/components/chat/message_type/file/VueLoadFile.vue";
import {getFileName} from "@/utils/message";
import {getFullTime} from "@/utils/time";
import CommonFile from "@/components/chat/message_type/file/helpers/CommonFile.vue";

interface BlobImage {
	url: string,
	type: string,
	name: string,
	file: File
}

export default defineComponent({
    name: "MessageImage",
    components: {CommonFile, Preloader, 'vue-load-image': VueLoadImage, MessageFile, VueLoadFile},
    props: {
        userMessage: {
            type: Boolean
        },
        message: {
            type: Object as PropType<TaskComment>
        },
        selectImage: {
            type: Function
        }
    },
    setup() {
        return {
            getFullTime
        }
    },
    data() {
        return {
            autoplayExtension: ['gif', 'webp'],
            imgExtension: ['jpg', 'jpeg', 'png', 'svg'],
            isLoading: false,
            url: `${process.env.VUE_APP_TASK_RADAR}`,
            attachments: this.$store.getters.getAllAttachments,
			isBlob: false,
            isNotSendFile: false,
			blobImages: [] as BlobImage[],
            nameImages: [] as string[],
        }
    },
	mounted() {
		let isBlob = false, isImageString = false

        console.log(this.message)
		this.message?.attachments.forEach((img) => {
			const image = img as unknown as number | string | ImagesPreview;

			if (typeof image === "object") {
				if (image.url.includes('blob')) {
					isBlob = true
					this.fetchBlob(image.url, image.file);
				}
			} else if (typeof image === 'string') {
                this.nameImages.push(image)
                isImageString = true
            }
		})

		this.isBlob = isBlob;
        this.isNotSendFile= isImageString;
	},
    methods: {
        getFileExtension(file: string) {
            if (file) {
                return file.split('.')[file.split('.').length - 1]
            }
        },
        downloadItem(file: string) {
            saveAs(this.url + file, getFileName(file))
        },
        getMessageById(id: number) {
            if (id) {
                const img = this.attachments.find((el: attachmentResponse) => el.id === id) as attachmentResponse
                if (img && img.file_path) {
                    return img.file_path
                }
            }
        },
		async fetchBlob(url: string, file: File) {
			try {
				const response = await fetch(url);
				const blob = await response.blob();
				this.blobImages.push({
					type: blob.type,
					url: URL.createObjectURL(blob),
					name: file.name,
					file: file,
				})
			} catch (error) {
				console.error('Error fetching blob:', error);
			}
		},
    }
})
