<template>
    <div class="main">
        <VNotification :status="timerStartNotification" :text="'Запустился учет времени'" :type="type.info">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0007 1.66675C5.40934 1.66675 1.66797 5.40812 1.66797 9.99943C1.66797 14.5907 5.40934 18.3321 10.0007 18.3321C14.592 18.3321 18.3333 14.5907 18.3333 9.99943C18.3333 5.40812 14.592 1.66675 10.0007 1.66675ZM13.9837 8.08291L9.25904 12.8075C9.14238 12.9242 8.98406 12.9909 8.81741 12.9909C8.65076 12.9909 8.49244 12.9242 8.37578 12.8075L6.01763 10.4494C5.77598 10.2077 5.77598 9.80778 6.01763 9.56613C6.25928 9.32448 6.65924 9.32448 6.90089 9.56613L8.81741 11.4826L13.1004 7.19965C13.3421 6.958 13.742 6.958 13.9837 7.19965C14.2253 7.4413 14.2253 7.83293 13.9837 8.08291Z"
                    fill="#009bf1"
                />
            </svg>
        </VNotification>
        <VNotification :status="timerFinishNotification" :text="'Учет времени остановлен'" :type="type.success">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0007 1.66675C5.40934 1.66675 1.66797 5.40812 1.66797 9.99943C1.66797 14.5907 5.40934 18.3321 10.0007 18.3321C14.592 18.3321 18.3333 14.5907 18.3333 9.99943C18.3333 5.40812 14.592 1.66675 10.0007 1.66675ZM13.9837 8.08291L9.25904 12.8075C9.14238 12.9242 8.98406 12.9909 8.81741 12.9909C8.65076 12.9909 8.49244 12.9242 8.37578 12.8075L6.01763 10.4494C5.77598 10.2077 5.77598 9.80778 6.01763 9.56613C6.25928 9.32448 6.65924 9.32448 6.90089 9.56613L8.81741 11.4826L13.1004 7.19965C13.3421 6.958 13.742 6.958 13.9837 7.19965C14.2253 7.4413 14.2253 7.83293 13.9837 8.08291Z"
                    fill="#00BB34"
                />
            </svg>
        </VNotification>
        <VNotification :status="timerErrorNotification" :text="timerError" :type="type.error">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9.00004" cy="9.00001" r="8.335" fill="#EC5555" />
                <path
                    d="M5.66504 12.3317L12.3317 5.66501"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12.3317 12.3317L5.66504 5.66501"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </VNotification>

        <slot v-if="noAccess">
            <NotFoundError title="Нет доступа к задаче" description="Скорее всего у вас нет доступа к этой задаче" />
        </slot>

        <slot v-else-if="nonexistentTask">
            <NotFoundError
                title="Задача не найдена"
                description="Скорее всего вы ввели несуществующую задачу, либо задача уже удалена"
            />
        </slot>

        <slot v-else-if="errorTask">
            <NotFoundError title="Не удалось загрузить задачу" description="Произошла ошибка при загрузке формы" />
        </slot>

        <div v-else>
            <Preloader v-if="isTaskLoaded && !noAccess" />
            <div class="body-task" v-else-if="activeTab === 'task' && !noAccess">
                <TaskForm
                    :rerender="rerender"
                    :task="task"
                    :login="login"
                    :catalogId="catalogId"
                    :parentElement="filteredArr"
                    :timeElement="timeElement"
                    :isLoading="isLoading"
                    :editPermission="editPermission"
                    @changeHeader="changeHeader"
                    @updateTaskStatusHandler="updateTaskStatusHandler"
                    :isPzForm="isPZForm"
                    :isTaskClosed="isTaskClosed"
                    :taskStatusAccept="taskStatusAccept"
                    :taskStatusAcceptError="taskStatusAcceptError"
                />
            </div>
            <div class="body-task" v-else-if="activeTab === 'tracker'">
                <WidgetManager
                    :getTask="getTask"
                    :activate="changeWidgetStatus"
                    :active="isWidgetButtonActive"
                    :isWidgetButtonActive="isWidgetButtonActive"
                    @closeWidgetStatus="closeWidgetStatus"
                    ref="widgetManager"
                    @changeHeader="changeHeader"
                    :isTaskClosed="isTaskClosed"
                    :taskStatusProps="taskStatusAccept"
                    @taskInit="taskInit"
                />
            </div>
            <div class="body-task" v-else-if="activeTab === 'chat' && !isLoadingChat">
                <Chat
                    :key="chatKey"
                    @changeHeader="changeHeader"
                    :task="task"
                    :allUsers="allUsers"
                    :spectators="task.spectators"
                    :approveUserAndDep="approveUserAndDep"
                    :addSpectatorsPermission="task.permissions.add_task_spectator"
                    :isLoading="isLoadingChat"
                    :isTaskClosed="isTaskClosed"
                    :step="task.steps"
                    :readUsers="readUsers"
                    :getMessages="getMessages"
                    :taskStatusProps="taskStatusAccept"
                    :chatError="chatError"
                    @updateTaskStatusHandler="updateTaskStatusHandler"
                    @clearReadUsers="clearReadUsers"
                    ref="Chat"
                />
            </div>
        </div>
        <TabBar :task="task" :selectTab="selectTab" :activeTab="activeTab" />
    </div>
</template>

<script>
import Preloader from '@/shared/ui/preloader';
import TaskForm from '@/components/TaskForm';
import TabBar from '@/components/TabBar';
import Chat from '@/components/Chat';
import { COMMENT_TYPE, CONSTANT_TASK_OBJECT } from '@/constants/constants';
import { LoadConditionResult, notificationStatus, TASK } from '@/utils/const';
import { getAllTaskFieldsRecursion, validateFieldVisibility } from '@/helpers/getAllTaskFields';
import { filter_messages, getUserById, getUserInfoById } from '@/utils/filter_messages';
import WidgetManager from '@/components/WidgetManager';
import NotFound from '@/views/NotFound';
import NotFoundError from '@/shared/ui/error/NotFoundError';
import { eventBus } from '@/main';
import { getTaskAcceptStatus } from '@/axios_requests/get_task_status';
import { CameraCodeScanner } from 'vue-barcode-qrcode-scanner';
import { updateTaskStatus } from '@/axios_requests/update_task_status';
import { setReadComment } from '@/axios_requests/post_comment_read';
import { TaskApi } from '@/shared/api';
import { ChatApi } from '@/shared/api/request/chat-api';
import { deleteTaskFromLocalStorage, getTaskFromMap } from '@/utils/storage/storage-task-methods';
import { loadMessageCondition, loadTaskCondition } from '@/utils/task';
import { addCommentInLocalStorage, getCommentsFromMap } from '@/utils/storage/storage-comment-method';
import { getUnsentCommentsFromMap } from '@/utils/storage/unsent-comment-method';
import VNotification from '@/shared/ui/notification';

export default {
    name: 'Task',
    components: {
        VNotification,
        NotFoundError,
        NotFound,
        WidgetManager,
        Chat,
        TabBar,
        TaskForm,
        Preloader,
        CameraCodeScanner,
    },
    data() {
        return {
            activeTab: 'task',
            catalogId: [],
            parentElement: [],
            filteredArr: [],
            isLoading: true,
            watchChat: false,
            errorTask: false,
            timeElement: null,
            workRecommended: null,
            isLoadingChat: true,
            chatError: false,
            task: {},
            messages: this.$store.getters.getPartMessages,
            showSystemMessage: this.$store.getters.getIsShowSystemMessage,
            allUsers: [],
            approveUserAndDep: {
                usersId: [],
                departmentId: [],
                user: [],
                department: [],
            },
            noAccess: false,
            nonexistentTask: false,
            editPermission: false,
            isTaskLoaded: true,
            isWidgetButtonActive: false,
            isPZForm: false,
            showTaskPart: 'Задача',
            login: null,
            isTaskClosed: false,
            rerender: false,
            taskStatusAccept: null,
            taskStatusAcceptError: false,
            readUsers: [],
            type: notificationStatus,
            timerFinishNotification: false,
            timerStartNotification: false,
            timerErrorNotification: false,
            departmentApproversIds: [],
        };
    },
    computed: {
        currentTab() {
            return this.$store.getters.getActiveTab;
        },
        isTimerStart() {
            return this.$store.getters.getIsTimerStart;
        },
        isTimerFinish() {
            return this.$store.getters.getIsTimerFinish;
        },
        timerError() {
            return this.$store.getters.getTimerError;
        },
        chatKey() {
            console.log(
                `${this.$store.getters.getActiveTab}__${this.approveUserAndDep.departmentId}__${this.task.spectators.departments.length}`
            );

            return `${this.$store.getters.getActiveTab}__${this.approveUserAndDep.departmentId}__${this.task.spectators.departments.length}`;
        },
    },
    async created() {
        const taskId = this.$route.params.id;

        // Если из кэша ничего не приходит, то this.task пустой
        const isTaskLoadedFromCache = this.getTaskFromCache(this.$route.params.id);

        if (isTaskLoadedFromCache) {
            this.allUsers = this.$store.getters.getAllUsers;
            await this.getCommentFromCache(this.$route.params.id);
        }

        await this.getTaskByRequest(taskId);

        this.isLoadingChat = false;
    },
    async mounted() {
        eventBus.$on('socketEvent', async (data) => {
            await this.getTaskBySocket(data);
        });

        this.departmentApproversIds = this.task.steps.flatMap((step) => step.departments || []).map((dep) => dep.id);

        this.activeTab = this.$store.getters.getActiveTab;

        let user = this.$store.getters.getCurrentUserId;
        this.login = await getUserInfoById(user);

        const header = document.querySelector('.burger-header');
        if (header) {
            header.textContent = this.showTaskPart;
        }

        this.showTaskPart = 'Задача';
    },
    beforeDestroy() {
        eventBus.$off('socketEvent');
        this.$store.dispatch('setTimerHeaderShow', false);
    },
    watch: {
        '$store.getters.getOnlineStatus'(value) {
            if (value) {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.ready
                        .then((reg) => {
                            return reg.sync.register('send-chats');
                        })
                        .then(() => {
                            console.log('Sync registered!');
                        })
                        .catch(() => {
                            console.log('Sync registration failed :(');
                        });
                }

                const taskId = this.$route.params.id;
                this.getTaskByRequest(taskId);
            }
        },
        isTimerStart(newVal) {
            if (newVal) {
                this.timerStartNotification = true;
                setTimeout(() => {
                    this.timerStartNotification = false;
                }, 5000);
            }
        },
        isTimerFinish(newVal) {
            if (newVal) {
                this.timerFinishNotification = true;
                setTimeout(() => {
                    this.timerFinishNotification = false;
                }, 5000);
            }
        },
        timerError(newVal) {
            if (newVal) {
                this.timerErrorNotification = true;
                setTimeout(() => {
                    this.timerErrorNotification = false;
                }, 5000);
            }
        },
        currentTab(newValue) {
            this.selectTab(newValue);
        },
        showTaskPart: function () {
            const header = document.querySelector('.burger-header');
            if (header) {
                header.textContent = this.showTaskPart;
            }
        },
        task: async function () {
            // Берет все fields которые приходят при загрузке страницы
            // и проверяет нужно ли их выводить, если да то записывает в новый массив
            // исключая ненужные поля
            if (this.task.fields && this.task.fields.length) {
                this.parentElement = this.task.fields.map((item) => {
                    if (CONSTANT_TASK_OBJECT.includes(item.name)) {
                        return {
                            id: item.id,
                            order: item.order,
                            name: item.name,
                            isOpen: true,
                            value: item.value ?? null,
                            info: item.info ?? null,
                            nested: item.fields ?? null,
                            fields: [],
                            inputType: item.type,
                            constName: this.getKeyByValue(TASK, item.name),
                        };
                    }
                });

                const endObj = {
                    constName: 'end',
                    fields: [],
                    id: Date.now(),
                    info: null,
                    inputType: 'title',
                    isOpen: true,
                    name: 'Итог',
                    nested: [],
                    order: Date.now(),
                    value: null,
                };

                for (let i = 0; i < this.parentElement.length; i++) {
                    const current = this.parentElement[i];

                    if (current && current.constName) {
                        const currentSplit = current.constName.split('___');

                        if (currentSplit.length > 1) {
                            // Если поле должно переместиться в итог
                            if (currentSplit.includes('end')) {
                                if (current.inputType === 'text') {
                                    current.type = COMMENT_TYPE.common;
                                } else {
                                    current.type = current.inputType;
                                }
                            }

                            // Смотрит изменяемое поле или нет
                            current.isEditable = currentSplit.includes('edit');
                            current.isVisible = true;

                            endObj.fields.push(current);
                            this.parentElement.splice(i, 1);
                            i--;
                        }
                    }

                    // Ищу заметки, которые должны быть в конце формы
                    if (current && current.constName && current.constName === 'fitter_note') {
                        if (current.info && current.info.content) {
                            if (
                                current.info.content ===
                                'Итог в комментарии к задаче о выявленных недочетах с целью их дальнейшего устранения'
                            ) {
                                current.value = current.info.content;
                                current.isEditable = false;
                                current.isVisible = true;

                                current.type = COMMENT_TYPE.note;

                                endObj.fields.push(current);
                                this.parentElement.splice(i, 1);
                                i--;
                            }
                        }
                    }
                }

                this.filteredArr = this.parentElement.reduce((accum, element) => {
                    if (element !== undefined) {
                        accum.push(element);
                    }

                    return accum;
                }, []);

                if (this.filteredArr.length === 0) {
                    this.isLoading = false;
                    this.errorTask = true;
                    return;
                }

                // Вытаскиваю время
                this.filteredArr = this.filteredArr.filter((item) => {
                    if (item.name === TASK.date || item.name === TASK.date_and_time || item.name === TASK.date_old) {
                        this.timeElement = item;
                    } else {
                        return item;
                    }
                });

                const mainObj = {
                    constName: 'main',
                    fields: [],
                    id: Date.now(),
                    info: null,
                    inputType: 'title',
                    isOpen: true,
                    name: 'Основное',
                    nested: [],
                    order: Date.now(),
                    value: null,
                };

                // Ищу все поля, которые лежат не в родителе
                // И записываю их в mainObject
                for (let i = 0; i < this.filteredArr.length; i++) {
                    const current = this.filteredArr[i];

                    if (current.fields.length === 0 && current.nested === null && current.constName !== 'fitter') {
                        if (
                            current.value ||
                            current.inputType === COMMENT_TYPE.file ||
                            current.inputType === COMMENT_TYPE.checkmark ||
                            current.name === TASK.fitter_note
                        ) {
                            current.isVisible = true;

                            mainObj.nested.push(current);
                            this.filteredArr.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (mainObj.nested.length > 0) {
                    this.filteredArr.unshift(mainObj);
                }

                // Фильтрует все вложенные элементы
                await this.getAllObjects();

                // Заношу поля в отдельные переменные, потому что они лежат в корне
                // Но из них не надо создавать отдельные блоки
                for (let i = 0; i < this.filteredArr.length; i++) {
                    if (this.filteredArr[i].name === TASK.work__recommend) {
                        this.workRecommended = this.filteredArr[i].fields;
                    }
                }

                // Поле
                let isExist = false;
                // Заношу их в раздел ход работ
                for (let i = 0; i < this.filteredArr.length; i++) {
                    if (
                        this.filteredArr[i].name === TASK.work__progress ||
                        this.filteredArr[i].name === TASK.work__progress_old
                    ) {
                        if (this.workRecommended && this.workRecommended.length > 0) {
                            // Проверяет показывать поле дополнительное оборудование или нет
                            for (let j = 0; j < this.filteredArr[i].fields.length; j++) {
                                if (this.filteredArr[i].fields[j].name === TASK.work__optional_equipment) {
                                    const item = this.filteredArr[i].fields[j];

                                    if (item && item.value) {
                                        isExist = true;
                                        const string = item.value.split(', ');

                                        // Проверка опциональности полей, подходит или нет
                                        for (let k = 0; k < this.workRecommended.length; k++) {
                                            const workRecommendedCondition =
                                                this.workRecommended[k].info.visibility_condition;

                                            let isFind = false;

                                            if (workRecommendedCondition && workRecommendedCondition.length === 0) {
                                                if (string.includes('ДУТ') || string.includes('2ДУТ')) {
                                                    isFind = true;
                                                }
                                            }

                                            if (
                                                workRecommendedCondition &&
                                                workRecommendedCondition.length > 0 &&
                                                workRecommendedCondition[0].value &&
                                                workRecommendedCondition[0].value.length > 0 &&
                                                string
                                            ) {
                                                string.forEach((item) => {
                                                    if (workRecommendedCondition[0].value.includes(item)) {
                                                        isFind = true;
                                                    }
                                                });
                                            }

                                            this.workRecommended[k].isVisible = isFind;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                if (!isExist) {
                    for (let i = 0; i < this.filteredArr.length; i++) {
                        if (this.filteredArr[i].name === TASK.work_info) {
                            if (this.workRecommended && this.workRecommended.length > 0) {
                                // Проверяет показывать поле дополнительное оборудование или нет
                                for (let j = 0; j < this.filteredArr[i].fields.length; j++) {
                                    if (this.filteredArr[i].fields[j].name === TASK.work__optional_equipment) {
                                        const item = this.filteredArr[i].fields[j];

                                        if (item && item.value) {
                                            isExist = true;
                                            const string = item.value.split(', ');

                                            // Проверка опциональности полей, подходит или нет
                                            for (let k = 0; k < this.workRecommended.length; k++) {
                                                const workRecommendedCondition =
                                                    this.workRecommended[k].info.visibility_condition;

                                                let isFind = false;

                                                if (
                                                    workRecommendedCondition &&
                                                    workRecommendedCondition.length > 0 &&
                                                    workRecommendedCondition[0].value &&
                                                    workRecommendedCondition[0].value.length > 0 &&
                                                    string
                                                ) {
                                                    string.forEach((item) => {
                                                        if (workRecommendedCondition[0].value.includes(item)) {
                                                            isFind = true;
                                                        }
                                                    });
                                                }

                                                this.workRecommended[k].isVisible = isFind;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // Заношу поля в отдельные переменные, потому что они лежат в корне
                // Но из них не надо создавать отдельные блоки
                for (let i = 0; i < this.filteredArr.length; i++) {
                    if (this.filteredArr[i].name === TASK.work__recommend) {
                        let isVisible = false;
                        for (let j = 0; j < this.filteredArr[i].fields.length; j++) {
                            if (this.filteredArr[i].fields[j].isVisible === true) {
                                isVisible = true;
                            }
                        }

                        if (!isVisible) {
                            this.filteredArr.splice(i, 1);
                        }
                    }
                }

                // Удаляю поле ход работы, если все элементы в нем скрыты
                for (let i = 0; i < this.filteredArr.length; i++) {
                    if (this.filteredArr && this.filteredArr[i] && this.filteredArr[i].name) {
                        if (this.filteredArr[i]?.name === TASK.work__progress) {
                            const current = this.filteredArr[i].fields;

                            let isVisible = false;
                            for (let j = 0; j < current.length; j++) {
                                if (current[j].isVisible === true && current[j].value) {
                                    isVisible = true;
                                }
                            }

                            if (!isVisible) {
                                this.filteredArr.splice(i, 1);
                            }
                        }

                        if (this.filteredArr[i]?.name === TASK.work__progress_old) {
                            const current = this.filteredArr[i].fields;

                            let isVisible = false;
                            for (let j = 0; j < current.length; j++) {
                                if (current[j].isVisible === true) {
                                    isVisible = true;
                                }
                            }

                            if (!isVisible) {
                                this.filteredArr.splice(i, 1);
                            }
                        }
                    }
                }

                // Удалить поле новый клиент
                for (let i = 0; i < this.filteredArr.length; i++) {
                    if (this.filteredArr[i].name === TASK.client__info) {
                        const current = this.filteredArr[i].fields;

                        for (let j = 0; j < current.length; j++) {
                            if (current[j].name === TASK.client__new) {
                                this.filteredArr[i].fields.splice(j, 1);
                            }
                        }
                    }
                }

                // Получение установщиков
                const fittersFields = this.parentElement.find((item) => {
                    if (item && item.constName) {
                        return item.constName === 'fitter';
                    }
                });

                const userObj = {
                    constName: 'user',
                    fields: [],
                    id: Date.now(),
                    info: null,
                    inputType: 'title',
                    isOpen: true,
                    name: 'Наблюдатели',
                    nested: [],
                    order: Date.now(),
                    value: null,
                };

                if (fittersFields) {
                    fittersFields.type = COMMENT_TYPE.router;
                    userObj.fields.push(fittersFields);

                    if (userObj.fields[0].value !== null) {
                        if (
                            typeof userObj.fields[0].value !== 'string' &&
                            userObj.fields[0].value.hasOwnProperty('users') &&
                            userObj.fields[0].value.hasOwnProperty('departments')
                        ) {
                            if ('users' in userObj.fields[0].value && 'departments' in userObj.fields[0].value) {
                                if (
                                    userObj.fields[0].value.users.length !== 0 ||
                                    userObj.fields[0].value.departments.length !== 0
                                ) {
                                    this.filteredArr.unshift(userObj);
                                }
                            }
                        }
                    }
                }

                if (endObj.fields.length > 0) {
                    let isShow = false;
                    for (let i = 0; i < endObj.fields.length; i++) {
                        const current = endObj.fields[i];
                        if (current.value) {
                            isShow = true;
                        }
                    }

                    if (isShow) {
                        this.filteredArr.push(endObj);
                    }
                }
            }

            this.isLoading = false;
            this.isTaskLoaded = false;
        },
    },
    destroyed() {
        this.$store.dispatch('changeActiveTab', 'task');

        const header = document.querySelector('.burger-header');
        if (header) {
            header.textContent = 'Список задач';
        }
        this.$store.dispatch('clearAllMessage');
    },
    methods: {
        taskInit(tableChange) {
            try {
                this.$refs.widgetManager.updateTask(tableChange);
            } catch (err) {
                console.log('Не смог обновить таск в виджете');
            }
        },
        clearReadUsers() {
            this.readUsers.length = 0;
        },
        async updateTaskStatusHandler(data) {
            this.taskStatusAcceptError = false;
            const taskStatusAccept = await updateTaskStatus(this.task.id, data);

            if (taskStatusAccept) {
                this.taskStatusAccept = taskStatusAccept;
            } else {
                this.taskStatusAcceptError = true;
            }
        },
        selectTab(tab) {
            this.activeTab = tab;
            this.$store.dispatch('changeEditMode', false);

            if (tab === 'chat') {
                if (!this.watchChat) {
                    this.watchChat = true;

                    const id = this.task.first_unread_comment_id;

                    if (!id) return;

                    if (id.toString() === this.$store.getters.getLastLoadMessageId.toString()) return;

                    this.$store.dispatch('changeLastLoadMessageId', id);
                    setReadComment(this.task.id, id);
                }
            }
        },
        async getAllObjects() {
            await getAllTaskFieldsRecursion(this.filteredArr);
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find((key) => object[key] === value);
        },
        changeHeader(title) {
            this.showTaskPart = title;
        },
        getTask() {
            return this.task;
        },
        changeWidgetStatus() {
            this.isWidgetButtonActive = !this.isWidgetButtonActive;
        },
        closeWidgetStatus() {
            this.isWidgetButtonActive = false;
        },
        async getTaskByRequest(taskId) {
            // Fetch the task from the API
            const response = await TaskApi.getTaskById(taskId);

            if (response.status_code === 403) {
                this.noAccess = true;
                this.isTaskLoaded = false;

                deleteTaskFromLocalStorage(taskId);

                return;
            }

            if (response && response.task) {
                this.task = response.task;

                const isTaskLoaded = loadTaskCondition(this.task, false, response);

                switch (isTaskLoaded) {
                    case LoadConditionResult.NONE_EXIST:
                        this.nonexistentTask = true;
                        break;
                    case LoadConditionResult.ERROR:
                        this.errorTask = true;
                        break;
                    case LoadConditionResult.SUCCESS:
                        this.task = response.task;
                        break;
                }

                // Получаю id согласующих
                this.task.steps.forEach((step) => {
                    if (step.users && step.users.length > 0) {
                        for (let i = 0; i < step.users.length; i++) {
                            const id = step.users[i].id;

                            if (!this.approveUserAndDep.usersId.includes(id)) {
                                this.approveUserAndDep.usersId.push(id);
                            }
                        }
                    }

                    if (step.departments && step.departments.length > 0) {
                        for (let i = 0; i < step.departments.length; i++) {
                            const id = step.departments[i].id;

                            if (!this.approveUserAndDep.departmentId.includes(id)) {
                                this.approveUserAndDep.departmentId.push(id);
                            }
                        }
                    }
                });

                if (this.task.read_users) {
                    this.readUsers = this.task.read_users;
                    this.readUsers = this.readUsers.filter(
                        (userId) => userId !== Number(this.$store.getters.getCurrentUserId)
                    );
                }

                if (this.task.attachments) {
                    this.$store.dispatch('changeMessageAttachments', this.task.attachments);
                }

                if (this.task.unread_comments) {
                    this.$store.dispatch('changeNewMessageCount', this.task.unread_comments);
                }

                if (this.task && this.task.permissions && this.task.permissions.edit_task) {
                    this.editPermission = true;
                }

                this.isTaskClosed = this.task.closed;

                this.allUsers = this.$store.getters.getAllUsers;

                await this.$store.dispatch('changeTask', this.task);
            }

            await this.getMessages();

            const taskStatusAccept = await getTaskAcceptStatus(Number(taskId));
            if (taskStatusAccept) {
                this.taskStatusAccept = taskStatusAccept;
            }
        },
        async getTaskBySocket(msg) {
            if (msg.data.task_id === this.task.id) {
                // Сообщение отредактировано
                if (msg.action === 'edit_comment') {
                    return await this.$store.dispatch('editMessage', msg.data);
                }

                if (
                    msg.data.type === 'system' &&
                    (msg.data.info?.action === 'change_spectators' || msg.data.info?.action === 'change_approvers')
                ) {
                    // if (this.$refs.Chat && typeof this.$refs.Chat.onSocketDepartmentChange === 'function') {
                    //     await this.$refs.Chat.onSocketDepartmentChange(msg.data.info);
                    // }

                    // Обновление упоминаний по сокетам, следит за изменением согласующих и наблюдателей.
                    if (msg.data.info.action === 'change_approvers') {
                        if (msg.data.info.type === 'department') {
                            if (msg.data.info.kind === 'add') {
                                this.approveUserAndDep.departmentId.push(msg.data.info.id);
                                console.log('approveUserAndDep', this.approveUserAndDep.departmentId);
                            } else if (msg.data.info.kind === 'remove') {
                                this.approveUserAndDep.departmentId = this.approveUserAndDep.departmentId.filter(
                                    (id) => id !== msg.data.info.id
                                );
                                console.log('approveUserAndDep', this.approveUserAndDep.departmentId);
                            }
                        }
                    }

                    if (msg.data.info.action === 'change_spectators') {
                        if (msg.data.info.type === 'department') {
                            if (msg.data.info.kind === 'add') {
                                this.task.spectators.departments.push({ id: msg.data.info.id, removable: true });
                            } else if (msg.data.info.kind === 'remove') {
                                this.task.spectators.departments = this.task.spectators.departments.filter(
                                    (dep) => dep.id !== msg.data.info.id
                                );
                            }
                        }
                    }
                }

                if (
                    msg.data.type === 'system' &&
                    (msg.data.info.action === 'change_approvers' ||
                        msg.data.info.action === 'change_spectators' ||
                        msg.data.info.action === 'change_values' ||
                        msg.data.info.action === 'routing' ||
                        msg.data.info.action === 'task_revoke_approves' ||
                        msg.data.info.action === 'task_closed' ||
                        msg.data.info.action === 'task_opened')
                ) {
                    let newSocketMessage = JSON.parse(JSON.stringify(msg.data));

                    if (this.activeTab === 'chat') {
                        console.log('set read active tab - chat');
                        setReadComment(this.task.id, newSocketMessage.id);
                    }

                    if (msg.data.info.action === 'task_closed') {
                        this.isTaskClosed = true;
                    }

                    if (msg.data.info.action === 'task_opened') {
                        this.isTaskClosed = false;
                    }
                }

                // Для изменений полей формы
                if (msg.data.type === 'system' && msg.data.info.action === 'change_values') {
                    // Try to get the task from the map
                    this.getTaskFromCache(this.$route.params.id);

                    const response = await TaskApi.getTaskById(this.$route.params.id);

                    if (!response || response['error']) {
                        return (this.errorTask = true);
                    }

                    if (response.task.attachments) {
                        this.$store.dispatch('changeMessageAttachmentsFull', response.task.attachments);
                    }

                    this.task = response.task;
                }

                if (msg.data.type === 'comment' || msg.data.type === 'system') {
                    let newSocketMessage = JSON.parse(JSON.stringify(msg.data));

                    newSocketMessage.user = await getUserById(newSocketMessage, this.allUsers);

                    await this.$store.dispatch('changeTaskState', { id: this.task.id, is_read: true });

                    this.readUsers.length = 0;

                    if (this.login && this.login.id !== newSocketMessage.author_id) {
                        this.readUsers.push(newSocketMessage.author_id);
                    }

                    // Изменять комментарий, когда приходят сокеты к нему (найти по тексту и вложению)
                    // Добавить id комментария, createdAt и info все остальные поля можно заполнить своими данными

                    // Если ошибка то удалять комментарий

                    if (newSocketMessage.attachments.length > 0) {
                        this.getTaskFromCache(this.$route.params.id);

                        const response = await TaskApi.getTaskById(this.$route.params.id);

                        if (!response || response['error']) {
                            return (this.errorTask = true);
                        }

                        if (response.task.attachments) {
                            this.$store.dispatch('changeMessageAttachmentsFull', response.task.attachments);
                        }
                    }

                    // Если это мое сообщение, то не добавляю его в массив
                    // В редактирую это сообщение в массиве

                    if (newSocketMessage.type === 'comment') {
                        // Поменял логику теперь если это мое сообщение (оно при отправке сразу добавляется в state)
                        // Здесь все равно отлавливается по сокетам и записывается в state еще раз и нужно удалить предыдущее

                        // Ищу сообщение свое которое было записано в store до сокетов
                        if (Number(newSocketMessage.author_id) === Number(this.$store.getters.getCurrentUserId)) {
                            let messages = this.$store.getters.getPartMessages;

                            const myMessage = messages.find(
                                (message) => message.text === newSocketMessage.text && message.beforeSocket
                            );

                            if (myMessage) {
                                await this.$store.dispatch('deleteMessageFromStateById', myMessage.id);
                            }
                        }

                        await this.$store.dispatch('addNewMessagesToStart', newSocketMessage);

                        addCommentInLocalStorage(this.task.id, newSocketMessage);
                    }

                    // Если это сообщение отправил не я, то писать что появилось новое сообщение
                    if (Number(newSocketMessage.author_id) !== Number(this.$store.getters.getCurrentUserId)) {
                        console.log('Не мое сообщение, ++');
                        let messageCount = this.$store.getters.getNewMessageCount;
                        messageCount += 1;
                        await this.$store.dispatch('changeNewMessageCount', messageCount);

                        const body_chat = document.querySelector('.body_chat');
                        const messages = this.$store.getters.getPartMessages;

                        if (body_chat && body_chat.scrollHeight < window.innerHeight - 34 - 46) {
                            // Минус размеры шапки
                            if (messages.length) {
                                this.$store.dispatch('changeNewMessageCount', 0);

                                const id = messages[messages.length - 1].id;
                                console.log('set read small screen');
                                setReadComment(this.task.id, id);
                            }
                        }
                    }

                    if (msg.data.type === 'system') {
                        await this.$store.dispatch('addNewMessagesToStart', newSocketMessage);
                    }
                }

                if (msg.action === 'comment_read') {
                    let newSocketMessage = JSON.parse(JSON.stringify(msg.data));

                    if (this.login.id !== newSocketMessage.user_id) {
                        this.readUsers.push(newSocketMessage.user_id);
                    }
                }

                // Для обновление виджета
                if (msg.action === 'new_comment' && msg.data.info && msg.data.info.action === 'change_values') {
                    const tableChange = msg.data.info.tables.length > 0;
                    this.taskInit(tableChange);
                }
            }
        },
        // Получаю первые сообщения при входе в чат
        async getPartMessage(messages, skipTop) {
            let partMessages = [];

            if (messages.allMessage.length === skipTop) {
                partMessages = messages.allMessage;
            } else {
                let counter = 0,
                    partArr = [];
                const partMessagesReverse = messages.allMessage.reverse();
                for (let i = 0; i < partMessagesReverse.length; i++) {
                    if (counter <= skipTop) {
                        const currentMessage = partMessagesReverse[i];
                        if (currentMessage.type !== 'system') {
                            counter++;
                        }

                        partArr.push(currentMessage);
                    }
                }

                partMessages = partArr.reverse();
            }

            if (partMessages.length) {
                await this.$store.dispatch('addNewPartMessages', partMessages);

                // Удалить эти сообщения из messagesPart
                await this.$store.dispatch('deleteNotSendMessageFromPart');

                // clear not send message
                await this.$store.dispatch('deleteNotSendMessage');

                const notSendMessagesFromState = getUnsentCommentsFromMap(this.task.id);
                if (notSendMessagesFromState && notSendMessagesFromState.length > 0) {
                    await this.$store.dispatch('addNotSendMessages', notSendMessagesFromState);
                }

                // Получить сообщения, которые не удалось отправить
                const notSendMessages = this.$store.getters.getAllErrorSendMessage;

                // Вставляю сообщения у которых была ошибка при отправке
                await this.$store.dispatch('addMessagePartEnd', notSendMessages);

                // Записываю id последнего полученного сообщения, что бы дальше получать новые сообщения начиная с него
                await this.$store.dispatch('changeLastLoadMessageId', partMessages[0].id);
            }
        },
        async getMessages() {
            const isMessageLoadFromCache = await this.getCommentFromCache(this.task.id);

            // Получает сообщения в чате
            const res = await ChatApi.getMessages(this.task.id, this.activeTab);

            if (res) {
                const isMessageLoaded = loadMessageCondition(isMessageLoadFromCache, res);

                switch (isMessageLoaded) {
                    case LoadConditionResult.NONE_EXIST:
                        this.chatError = true;
                        break;
                    case LoadConditionResult.ERROR:
                        this.chatError = true;
                        break;
                    case LoadConditionResult.SUCCESS:
                        await this.getMessageWhenMount(res);
                        break;
                }
            }
        },
        getTaskFromCache(id) {
            const taskFromMap = getTaskFromMap(id);

            if (taskFromMap) {
                this.isPreloaderActive = false;
                this.task = taskFromMap;

                return true;
            }
        },
        async getCommentFromCache(id) {
            const commentsMap = getCommentsFromMap(id);

            if (commentsMap) {
                await this.getMessageWhenMount(commentsMap);
                this.isLoadingChat = false;

                return true;
            }
        },
        async getMessageWhenMount(comments) {
            const messages = await filter_messages(comments, this.allUsers, this.showSystemMessage);

            await this.$store.dispatch('addMessages', messages.allMessage);
            await this.getPartMessage(messages, this.$store.getters.getLimit);

            const maxIdItem = messages.allMessage.reduce((accumulator, current) => {
                return accumulator.id > current.id ? accumulator : current;
            });

            await this.$store.dispatch('changeFirstUnreadComment', maxIdItem.id);
        },
        findFileDepOnParent(item, field_id, condition) {
            let value = [];
            if ('fields' in item) {
                if (item.fields) {
                    item.fields.find((el) => {
                        if (el.info && el.info.visibility_condition && el.info.visibility_condition.length > 0) {
                            el.info.visibility_condition.forEach((elInfo) => {
                                if (elInfo.field_id === field_id) {
                                    const elementConditionType = elInfo.condition_type;
                                    const elementValue = elInfo.value;
                                    const conditionValue = condition.value;

                                    const resIsVisible = validateFieldVisibility(
                                        condition,
                                        elementConditionType,
                                        conditionValue,
                                        elementValue
                                    );
                                    el.isVisible = Boolean(resIsVisible);
                                }
                            });
                        } else {
                            this.findFileDepOnParent(el, field_id);
                        }
                    });
                }
                return value;
            }
            return value;
        },
    },
};
</script>

<style scoped></style>
