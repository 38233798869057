import { TASK } from '@/utils/const';

export const CONSTANT_Arr_Months = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
];
export const CONSTANT_cRout_activePanel = [
    'Задача',
    'Трекер',
    'Чат',
    'Поиск',
    'Настройки профиля',
    'Инструкция',
    'Не найдено',
];
export const CONSTANT_TASK_OBJECT = Object.values(TASK);
export const COMMENT_TYPE = {
    common: 'common',
    address: 'address',
    phone: 'phone',
    money: 'money',
    router: 'router',
    checkmark: 'checkmark',
    file: 'file',
    note: 'note',
    catalog: 'catalog',
    textfield: 'textfield',
    title: 'title',
};

export const task_selected_tab = {
    all: 'all',
    my: 'my',
};

export const APP_LINK = ['rulse.ru', 'fitter.rulse', 'localhost', 'test.tspb.su', 'https://ofc-test-01.tspb.su'];

export enum ExtraUsersForMention {
    EVGENIY_BORODIN = 57,
    ALEKSANDRA_BEREZINA = 61,
}
