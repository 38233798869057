import axios from 'axios';

const API_URL = `${process.env.VUE_APP_GEO_SEARCH_API}/oitask/geo_search`;

interface GeoSearchResponse {
  full_address: string;
  x: number;
  y: number;
}

type GeoSearchResult = GeoSearchResponse[];

const TOKEN_KEY = 'current_user';
const USER_ID_KEY = 'current_user_id';

export const getGeoSearch = async (
  address: string
): Promise<GeoSearchResult | string> => {
  const token = localStorage.getItem(TOKEN_KEY);
  const userId = localStorage.getItem(USER_ID_KEY);

  if (!token) {
    return 'Не авторизован';
  }
  try {
    const response = await axios({
      method: 'POST',
      url: API_URL,
      headers: {
        Authorization: `Token ${token}`,
        Id: userId,
      },
      withCredentials: true,
      data: {
        search_string: address,
      },
    });

    if (response.status === 200) {
      return response.data as GeoSearchResult;
    }

    console.error('Не удалось получить данные по адресу', address);
    return [] as GeoSearchResult;
  } catch (error) {
    console.error('Ошибка при получении геоданных:', error);
    return [] as GeoSearchResult;
  }
};
