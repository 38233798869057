import {LocalStorageService} from "@/utils/storage";
import {STORAGE_KEY} from "@/utils/const";
import Timer from "@/components/timer/ui/Timer.vue";


export function saveTimersList(name: string, key: keyof typeof STORAGE_KEY, value: number) {
    // Получаем текущие данные из локального хранилища
    const TimersList = LocalStorageService.getInstance().getItem(key);
    const TimersMap = LocalStorageService.localStorageToMap(TimersList) || new Map<string, number>();

    // Обновляем карту, добавляя новое значение
    TimersMap.set(name, value);

    // Преобразуем карту в объект и сохраняем в локальном хранилище
    const mapToObject = LocalStorageService.mapToLocalStorage(TimersMap);
    LocalStorageService.getInstance().rewriteData(key, mapToObject);
}


export function getTaskTimer(name: string | number) {
    const TimersList = LocalStorageService.getInstance().getItem(STORAGE_KEY.timer_value) as unknown as Record<string | number, number> | null;
    const TimersListMap = LocalStorageService.localStorageToMap(TimersList);

    const key = name.toString();
    if (TimersListMap.has(key)) {
        return TimersListMap.get(key);
    } else {
        return null;
    }

}


export function deleteTaskTimer(name: number) {
    const TimersList = LocalStorageService.getInstance().getItem(STORAGE_KEY.timer_value);
    const TimersListMap = LocalStorageService.localStorageToMap(TimersList);
    const mapToObject = LocalStorageService.mapToLocalStorage(TimersListMap);
    delete mapToObject[name];
    LocalStorageService.getInstance().rewriteData(STORAGE_KEY.timer_value, mapToObject);
}
