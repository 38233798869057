import {Commit} from "vuex";

type State = {
    isTimerStart: boolean,
    isTimerFinish: boolean,
    timerValue: number | null,
    finishTimeValue: number | null,
    isTimerRunning: boolean,
    timerError: string,
    isTimerPopoverOpen: boolean,
    isTimerHeaderShow: boolean,
}

export default {
    state: {
        isTimerStart: false,
        isTimerFinish: false,
        timerValue: null,
        finishTimeValue: null,
        isTimerRunning: false,
        timerError: '',
        isTimerPopoverOpen: false,
        isTimerHeaderShow: false,
    },
    mutations: {
        startTimer(state: State, payload: boolean) {
            state.isTimerStart = payload;
            state.isTimerFinish = !payload;
        },
        finishTimer(state: State, payload: boolean) {
            state.isTimerFinish = payload;
            state.isTimerStart = !payload;
        },
        setTimerValue(state: State, payload: number) {
            state.timerValue = payload;
        },
        setFinishTimeValue(state: State, payload: number) {
            state.finishTimeValue = payload;
        },
        setTimerState(state: State, payload: boolean) {
            state.isTimerFinish = payload;
            state.isTimerStart = payload;
        },
        setIsTimerRunning(state: State, payload: boolean) {
            state.isTimerRunning = payload;
        },
        setTimerError(state: State, payload: string) {
            state.timerError = payload;
        },
        setTimerPopoverOpen(state: State, payload: boolean) {
            state.isTimerPopoverOpen = payload;
        },
        setTimerHeaderShow(state: State, payload: boolean) {
            state.isTimerHeaderShow = payload;
        },
    },
    actions: {
        startTimer({commit}: {commit: Commit}, payload: boolean) {
            commit("startTimer", payload)
        },
        finishTimer({commit}: {commit: Commit}, payload: boolean) {
            commit("finishTimer", payload)
        },
        setFinishTimeValue({commit}: {commit: Commit}, payload: number | null) {
            commit("setFinishTimeValue", payload)
        },
        setTimerValue({commit}: {commit: Commit}, payload: number | null) {
            commit("setTimerValue", payload)
        },
        setTimerState({commit}: {commit: Commit}, payload: boolean) {
            commit("setTimerState", payload)
        },
        setIsTimerRunning({commit}: {commit: Commit}, payload: boolean) {
            commit("setIsTimerRunning", payload)
        },
        setTimerError({commit}: {commit: Commit}, payload: string) {
            commit("setTimerError", payload)
        },
        setTimerPopoverOpen({commit}: {commit: Commit}, payload: boolean) {
            commit("setTimerPopoverOpen", payload)
        },
        setTimerHeaderShow({commit}: {commit: Commit}, payload: boolean) {
            commit("setTimerHeaderShow", payload)
        }
    },
    getters: {
        getIsTimerStart(state: State) {
            return state.isTimerStart
        },
        getIsTimerFinish(state: State) {
            return state.isTimerFinish
        },
        getTimerValue(state: State) {
            return state.timerValue
        },
        getFinishTimeValue(state: State) {
            return state.finishTimeValue
        },
        getIsTimerRunning(state: State) {
            return state.isTimerRunning
        },
        getTimerError(state: State) {
            return state.timerError
        },
        getTimerPopoverOpen(state: State) {
            return state.isTimerPopoverOpen
        },
        getTimerHeaderShow(state: State) {
            return state.isTimerHeaderShow
        }
    }
}
