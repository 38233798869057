import {Commit} from "vuex";
import {TaskDataType, CatalogDataType, Catalog} from "@/shared/api";
import {task_selected_tab} from "@/constants/constants";
import {LocalStorageService} from "@/utils/storage";
import {STORAGE_KEY} from "@/utils/const";
import {mergeTwoTaskList, updateTaskStorage} from "@/utils/storage/storage-tasklist-methods";
import {StorageTaskList} from "@/shared/api/types/storage";

type TaskListElementRead = {
    id: number,
    is_read: boolean
}

type State = {
    task_selected: number,
    prev_tasK_id: number | null,
    my_tasks_updated: boolean,
    all_tasks_updated: boolean,
    rerender_list: boolean,
    task_selected_tab: string,
    active_tab: string,
    all_tasks: TaskDataType[] | null,
    my_tasks_count: number | null,
    all_tasks_count: number | null,
    is_task_updated: boolean,
    task: TaskDataType,
    catalog: CatalogDataType[],
    catalogsHeader: Catalog[],
    catalogFull: boolean,
    openListName: string,
}

interface AddTask {
    tasks: TaskDataType[],
    tab: 'my' | 'all'
}

const emptyTask = {
    id: 0,
    name: [],
    form_id: 0,
    form_name: "",
    date: 0,
    closed: false,
    step_id: 0,
    pinned_links: [],
    fields: [],
    steps: [],
    spectators: {
        users: [],
        departments: []
    },
    permissions: {
        get_task: false,
        create_task: false,
        edit_task: false,
        open_task: false,
        close_task: false,
        write_comments: false,
        add_task_spectator: false,
        delete_task_spectator: false,
        add_task_approver: false,
        delete_task_approver: false,
        revoke_approvals: false,
    },
    first_unread_comment_id: 0,
    last_activity: 0,
    unread_comments: 0,
    read_users: [],
} as unknown as TaskDataType

export default {
    state: {
        rerender_list: false,
        task_selected: null,
        prev_task_id: null,
        all_tasks_updated: false,
        my_tasks_updated: false,
        task_selected_tab: LocalStorageService.getInstance().getString(STORAGE_KEY.task_selected_tab) || task_selected_tab.my,
        active_tab: LocalStorageService.getInstance().getString(STORAGE_KEY.activeTab) || 'task', // chat, tracker
        all_tasks: (LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as unknown as StorageTaskList)?.tasks,
        my_tasks_count: (LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as unknown as StorageTaskList)?.tasks_id?.length,
        all_tasks_count: (LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as unknown as StorageTaskList)?.all_tasks_id?.length,
        is_task_updated: false,
        task: emptyTask,
        catalog: [],
        catalogsHeader: [],
        catalogFull: false,
        openListName: '',
    },
    mutations: {
        addTaskSelected(state: State, payload: number) {
            state.task_selected = payload
        },
        addTasks(state: State, payload: AddTask) {
            state.all_tasks = payload.tasks

            if (!state) return

            let allTasks = state.all_tasks

            const taskAlreadySaved = LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as unknown as StorageTaskList

            if (taskAlreadySaved && taskAlreadySaved.tasks) {
                allTasks = mergeTwoTaskList(state.all_tasks, taskAlreadySaved.tasks)
            }


            if (state && state.all_tasks) {
                const storageData = updateTaskStorage(state.all_tasks, allTasks, payload.tab)

                LocalStorageService.getInstance().saveData(STORAGE_KEY.taskList, storageData)

                state.my_tasks_count = (LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as unknown as StorageTaskList)?.tasks_id?.length ?? null
                state.all_tasks_count = (LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as unknown as StorageTaskList)?.all_tasks_id?.length ?? null
            }
        },
        rerenderTasksList(state: State, payload: boolean) {
            state.rerender_list = payload
        },
        changeTaskState(state: State, payload: TaskListElementRead) {
            const newTasks = state.all_tasks?.map(item => {
                if (item.id.toString() === payload.id.toString()) {
                    item.unread_comments = Number(payload.is_read)

                    return item
                }

                return item
            })

            if (!newTasks) return

            state.all_tasks = newTasks
        },
        putTaskUpdated(state: State, payload: boolean) {
            state.is_task_updated = payload
        },
        changeLastMessageInTask(state: State, payload: {taskId: number, message: string}) {
            if (!state.all_tasks) return

            state.all_tasks = state.all_tasks.map(task => {
                if (task.id === payload.taskId) {
                    (task as any).comment = payload.message
                }

                return task
            })
        },
        changeTask(state: State, payload: TaskDataType) {
            state.task = payload;
        },
        changeCatalog(state: State, payload: CatalogDataType) {
            const catalogIndex = state.catalog.findIndex(
                (catalog) => catalog.id === payload.id
            );

            if (catalogIndex === -1) {
                state.catalog.push(payload);
            }
        },
        setCatalogHeader(state: State, payload: Catalog[]) {
            state.catalogsHeader = payload
        },
        changeTaskSelectedTab(state: State, payload: string) {
            LocalStorageService.getInstance().saveItem(STORAGE_KEY.task_selected_tab, payload as string)
            state.task_selected_tab = payload

            state.my_tasks_count = (LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as unknown as StorageTaskList)?.tasks_id?.length ?? null
            state.all_tasks_count = (LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as unknown as StorageTaskList)?.all_tasks_id?.length ?? null
        },
        changeActiveTab(state: State, payload: string) {
            state.active_tab = payload

            LocalStorageService.getInstance().saveItem(STORAGE_KEY.activeTab, payload as any)
        },
        changeMyTasksCount(state: State, payload: number) {
            state.my_tasks_count = payload
        },
        changeAllTasksCount(state: State, payload: number) {
            state.all_tasks_count = payload
        },
        changeOpenListName(state: State, payload: string) {
            state.openListName = payload
        },
        changeAllTaskUpdatedValue(state: State, payload: boolean) {
            state.all_tasks_updated = payload
        },
        changeMyTaskUpdatedValue(state: State, payload: boolean) {
            state.my_tasks_updated = payload
        },
        changePrevTaskId(state: State, payload: number | null) {
            state.prev_tasK_id = payload
        },
    },
    actions: {
        addTaskSelected({commit}: {commit: Commit}, payload: number) {
            commit('addTaskSelected', payload)
        },
        changeTaskState({commit}: {commit: Commit}, payload: TaskListElementRead) {
            commit('changeTaskState', payload)
        },
        addTasks({commit}: {commit: Commit}, payload: AddTask) {
            commit('addTasks', payload)
        },
        putTaskUpdated({commit}: {commit: Commit}, payload: boolean) {
            commit('putTaskUpdated', payload)
        },
        changeTask({commit}: {commit: Commit}, payload: TaskDataType) {
            commit("changeTask", payload);
        },
        changeCatalog({commit}: {commit: Commit}, payload: CatalogDataType) {
            commit("changeCatalog", payload);
        },
        setCatalogHeader({commit}: {commit: Commit}, payload: Catalog[]) {
            commit("setCatalogHeader", payload);
        },
        changeLastMessageInTask({commit}: {commit: Commit}, payload: {taskId: number, message: string}) {
            commit("changeLastMessageInTask", payload);
        },
        changeTaskSelectedTab({commit}: {commit: Commit}, payload: string) {
            commit("changeTaskSelectedTab", payload);
        },
        changeActiveTab({commit}: {commit: Commit}, payload: string) {
            commit("changeActiveTab", payload);
        },
        changeMyTasksCount({commit}: {commit: Commit}, payload: number) {
            commit("changeMyTasksCount", payload);
        },
        changeAllTasksCount({commit}: {commit: Commit}, payload: number) {
            commit("changeAllTasksCount", payload);
        },
        rerenderTasksList({commit}: {commit: Commit}, payload: boolean) {
            commit("rerenderTasksList", payload)
        },
        changeOpenListName({commit}: {commit: Commit}, payload: string) {
            commit("changeOpenListName", payload)
        },
        changeMyTaskUpdatedValue({commit}: {commit: Commit}, payload: boolean) {
            commit("changeMyTaskUpdatedValue", payload)
        },
        changeAllTaskUpdatedValue({commit}: {commit: Commit}, payload: boolean) {
            commit("changeAllTaskUpdatedValue", payload)
        },
        changePrevTaskId({commit}: {commit: Commit}, payload: number | null) {
            commit("changePrevTaskId", payload)
        },
    },
    getters: {
        getTaskSelected(state: State) {
            return state.task_selected
        },
        getTasks(state: State) {
            return state.all_tasks
        },
        getMyTasksCount(state: State) {
            return state.my_tasks_count
        },
        getAllTasksCount(state: State) {
            return state.all_tasks_count
        },
        getTaskStatus(state: State) {
            return state.is_task_updated
        },
        getFormField(state: State) {
            return state.task.fields
        },
        getTaskSelectedInfo(state: State) {
            return state.task
        },
        getCatalogs(state: State) {
            return state.catalog;
        },
        getCatalogsHeader(state: State) {
            return state.catalogsHeader;
        },
        getTaskSelectedTab(state: State) {
            return state.task_selected_tab
        },
        getActiveTab(state: State) {
            return state.active_tab
        },
        getRerenderList(state: State) {
            return state.rerender_list
        },
        getOpenListName(state: State) {
            return state.openListName
        },
        getMyTaskUpdatedValue(state: State) {
            return state.my_tasks_updated
        },
        getAllTaskUpdatedValue(state: State) {
            return state.all_tasks_updated
        },
        getPrevTaskId(state: State) {
            return state.prev_tasK_id
        },
    }
}
