import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from 'vue-apexcharts';
import * as Sentry from "@sentry/vue";
// @ts-ignore
import VueMask from 'v-mask'
// @ts-ignore
import vClickOutside from 'v-click-outside'
// @ts-ignore
import  "./registerServiceWorker.js";
import "./app/style/main.css";
import store from "./store";
// @ts-ignore
import VCalendar from 'v-calendar';
// @ts-ignore
import wb from "./registerServiceWorker";
export const eventBus = new Vue()
Vue.prototype.$workbox = wb;


Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts);
Vue.use(VCalendar);
Vue.use(VueMask);
Vue.use(vClickOutside)

let sentry_key = 'dev_key'

if (window.location.hostname === 'ofc-test-01.tspb.su' || window.location.hostname === 'fitter.rulse.ru') {
    sentry_key = process.env.VUE_APP_SENTRY
}


Sentry.init({
    Vue,
    dsn: sentry_key,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
            // Убедитесь, что маскировка данных отключена
            maskAllText: false, // Не маскировать текст
            maskAllInputs: false, // Не маскировать поля ввода
            blockAllMedia: false, // Не блокировать медиа (например, изображения)
        }),
    ],
    beforeSend(event) {
        // Игнорировать ошибки, связанные с уведомлениями
        if (event.message && event.message.includes('Notification permission request failed')) {
            return null; // Пропустить событие
        }
        return event; // Отправить остальные события
    },
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", "ofc-test-01.tspb.su", "fitter.rulse.ru"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

new Vue({
    //@ts-ignore
    VCalendar,
    VueMask,
    store,
    router,
    render: (h) => h(App)
}).$mount("#app");
