import {StorageComment, StorageTask} from "@/shared/api/types/storage";
import {hasOneWeekPassed} from "@/utils/time";
import {LocalStorageService} from "@/utils/storage";
import {STORAGE_KEY, VERSION} from "@/utils/const";

export function deleteValueFromMapByTimestamp<T>(map: Map<string, T>): Map<string, T> {
    const keysToRemove: string[] = [];

    for (const [key, value] of map.entries() as unknown as [string, StorageTask | StorageComment][]) {
        if (hasOneWeekPassed(value.timestamp)) {
            keysToRemove.push(key);
        }
    }

    for (const keyToRemove of keysToRemove) {
        map.delete(keyToRemove);
    }

    return map
}

export function clearLocalStorage() {
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.task)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.taskList)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.comments)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.users)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.activeTab)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.task_selected_tab)
}

export function setStorageVersionCorrect() {
    LocalStorageService.getInstance().saveItem(STORAGE_KEY.cache_version, VERSION)
}

export function clearAllLocalStorage() {
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.taskList)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.users)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.task)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.cache_version)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.comments)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.current_user_data)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.current_user)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.current_user_id)
    LocalStorageService.getInstance().removeItem(STORAGE_KEY.activeTab)
}

export function checkStorageVersion() {
    const version = LocalStorageService.getInstance().getItem(STORAGE_KEY.cache_version)

    if (!version || version.toString() !== VERSION) {
        return false
    }

    return true
}