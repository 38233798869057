
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import PdfPreview from "@/components/chat/message_type/file/helpers/PdfPreview.vue";
import TextPreview from "@/components/chat/message_type/file/helpers/TextPreview.vue";
import CommonFile from "@/components/chat/message_type/file/helpers/CommonFile.vue";
import { getFileName } from "@/utils/message";

const Status = {
  PENDING: "pending",
  LOADING: "loading",
  LOADED: "loaded",
  FAILED: "failed",
};

export default defineComponent({
  name: "MessageFile",
  components: { CommonFile, TextPreview, PdfPreview },
  props: {
    userMessage: {
      type: Boolean,
      required: true,
    },
    file: {
      type: [Number, Object, File],
      required: true,
    },
    getMessageById: {
      type: Function,
      required: true,
    },
    downloadItem: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const status = ref(Status.PENDING);
    const fileUrl = ref<string | null>(null);
    const fileName = ref<string | null>(null);
    const fileExtension = ref<string | null>(null);

    const fetchFile = async () => {
      try {
        status.value = Status.LOADING;
        const url = props.getMessageById(props.file);
        if (url) {
          fileUrl.value = url;
          status.value = Status.LOADED;
        } else {
          throw new Error("File not found");
        }
      } catch (error) {
        status.value = Status.FAILED;
        console.error("Error loading file:", error);
      }
    };

    onMounted(() => {
      fileName.value = typeof props.file === "number"
        ? getFileName(props.getMessageById(props.file))
        : getFileName(props.file.name);

      if (fileName.value) {
        const parts = fileName.value.split(".");
        if (parts.length > 1) {
          fileExtension.value = parts[parts.length - 1].toLowerCase();
        }
      }

      fetchFile();
    });

    watch(() => props.file, () => {
      fetchFile();
    });

    const fileTextNameColor = computed(() => {
      if (props.userMessage && (fileExtension.value === "pdf" || fileExtension.value === "txt")) {
        return "white";
      }
      return "#009bf1";
    });

    return {
      status,
      fileUrl,
      fileName,
      fileExtension,
      fileTextNameColor,
    };
  },
});
